import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147'),
	() => import('./nodes/148'),
	() => import('./nodes/149'),
	() => import('./nodes/150'),
	() => import('./nodes/151'),
	() => import('./nodes/152'),
	() => import('./nodes/153'),
	() => import('./nodes/154'),
	() => import('./nodes/155'),
	() => import('./nodes/156'),
	() => import('./nodes/157'),
	() => import('./nodes/158')
];

export const server_loads = [0,2,3,8,12];

export const dictionary = {
		"/": [22],
		"/(protected)/admin/(pages)/businesses": [26,[2,3,4]],
		"/(protected)/admin/(forms)/businesses/new": [23,[2,3]],
		"/(protected)/admin/(pages)/businesses/[id]": [27,[2,3,4]],
		"/(protected)/admin/(pages)/enterprises": [28,[2,3,4]],
		"/(protected)/admin/(forms)/enterprises/new": [24,[2,3]],
		"/(protected)/admin/(pages)/enterprises/[id]": [29,[2,3,4]],
		"/(protected)/admin/(pages)/printers": [30,[2,3,4]],
		"/(protected)/admin/(pages)/readers": [31,[2,3,4]],
		"/(protected)/admin/(pages)/users": [32,[2,3,4]],
		"/(protected)/admin/(forms)/users/new": [25,[2,3]],
		"/(protected)/admin/(pages)/users/[id]": [33,[2,3,4]],
		"/(unprotected)/challenge/[id]": [~149],
		"/(unprotected)/changelog": [150],
		"/(protected)/customers/(pages)/transactions/checks": [34,[2,5]],
		"/(protected)/customers/(pages)/transactions/checks/[id]": [35,[2,5]],
		"/(protected)/customers/(pages)/transactions/payments": [36,[2,5]],
		"/(protected)/dashboard": [37,[2]],
		"/(unprotected)/error": [151],
		"/(protected)/finance/(pages)": [50,[2,6,7]],
		"/(protected)/finance/(pages)/accounts": [51,[2,6,7]],
		"/(protected)/finance/(forms)/add-funds": [38,[2,6]],
		"/(protected)/finance/(forms)/add-funds/debit": [39,[2,6]],
		"/(protected)/finance/(forms)/add-funds/transfer": [40,[2,6]],
		"/(protected)/finance/(forms)/agreements": [41,[2,6]],
		"/(protected)/finance/(pages)/all-cards": [52,[2,6,7]],
		"/(protected)/finance/(forms)/all-cards/new": [42,[2,6]],
		"/(protected)/finance/(pages)/bills": [53,[2,6,7]],
		"/(protected)/finance/(pages)/expenses": [54,[2,6,7]],
		"/(protected)/finance/(forms)/link-account": [43,[2,6]],
		"/(protected)/finance/(forms)/make-payment": [44,[2,6]],
		"/(protected)/finance/(pages)/my-cards": [55,[2,6,7]],
		"/(protected)/finance/(forms)/onboard": [45,[2,6]],
		"/(protected)/finance/(forms)/pay-bill": [46,[2,6]],
		"/(protected)/finance/(forms)/pending": [47,[2,6]],
		"/(protected)/finance/(pages)/recipients": [56,[2,6,7]],
		"/(protected)/finance/(forms)/recipients/new": [48,[2,6]],
		"/(protected)/finance/(pages)/spend-limits": [57,[2,6,7]],
		"/(protected)/finance/(forms)/spend-limits/new": [49,[2,6]],
		"/(protected)/food-and-beverages/(pages)/availability": [58,[2,8]],
		"/(protected)/food-and-beverages/(pages)/discounts": [59,[2,8]],
		"/(protected)/food-and-beverages/(pages)/menus": [60,[2,8]],
		"/(protected)/food-and-beverages/(pages)/menus/[id]/active-instances": [61,[2,8,9]],
		"/(protected)/food-and-beverages/(pages)/menus/[id]/availability": [62,[2,8,9]],
		"/(protected)/food-and-beverages/(pages)/menus/[id]/edit": [63,[2,8,9]],
		"/(protected)/food-and-beverages/(pages)/menus/[id]/publish": [64,[2,8,9]],
		"/(protected)/food-and-beverages/(pages)/modifier-groups": [65,[2,8]],
		"/(protected)/food-and-beverages/(pages)/modifier-options": [66,[2,8]],
		"/(protected)/food-and-beverages/(pages)/products": [67,[2,8]],
		"/(unprotected)/forgot-password": [152],
		"/(protected)/help/[id]": [~68,[2]],
		"/(protected)/inventory/(pages)/allergens": [69,[2,10]],
		"/(protected)/inventory/(pages)/counts/past": [71,[2,10,11]],
		"/(protected)/inventory/(pages)/counts/past/[id]": [72,[2,10,11]],
		"/(protected)/inventory/(pages)/counts/past/[id]/variance/[itemId]": [73,[2,10,11]],
		"/(protected)/inventory/(pages)/counts/recurring": [74,[2,10,11]],
		"/(protected)/inventory/(pages)/counts/upcoming": [75,[2,10,11]],
		"/(protected)/inventory/(pages)/counts/[id]": [70,[2,10,11]],
		"/(protected)/inventory/(pages)/groups": [76,[2,10]],
		"/(protected)/inventory/(pages)/groups/[id]": [77,[2,10]],
		"/(protected)/inventory/(pages)/invoices/new": [79,[2,10]],
		"/(protected)/inventory/(pages)/invoices/[id]": [78,[2,10]],
		"/(protected)/inventory/(pages)/items": [80,[2,10]],
		"/(protected)/inventory/(pages)/items/[id]": [81,[2,10]],
		"/(protected)/inventory/(pages)/locations": [82,[2,10]],
		"/(protected)/inventory/(pages)/measurements/conversions": [83,[2,10]],
		"/(protected)/inventory/(pages)/measurements/table": [84,[2,10]],
		"/(protected)/inventory/(pages)/recipes/prep-items": [85,[2,10]],
		"/(protected)/inventory/(pages)/recipes/prep-items/[id]": [86,[2,10]],
		"/(protected)/inventory/(pages)/vendors": [87,[2,10]],
		"/(protected)/inventory/(pages)/vendors/[id]": [88,[2,10]],
		"/(unprotected)/join/[id]": [153],
		"/(unprotected)/login": [154],
		"/(protected)/overview": [89,[2]],
		"/(protected)/people/(new)/dailies": [100,[2,14]],
		"/(protected)/people/(new)/dailies/[date]/logs": [101,[2,14,15]],
		"/(protected)/people/(new)/payroll": [102,[2,14]],
		"/(protected)/people/(new)/payroll/[startDate]/[endDate]": [103,[2,14]],
		"/(protected)/people/(new)/payroll/[startDate]/[endDate]/[staffId]": [104,[2,14]],
		"/(protected)/people/(legacy)/scheduling": [~90,[2]],
		"/(protected)/people/(legacy)/scheduling/requests/availabilities/[status]": [94,[2,13]],
		"/(protected)/people/(legacy)/scheduling/requests/cover-requests/[status]": [95,[2,13]],
		"/(protected)/people/(legacy)/scheduling/requests/drop-requests/[status]": [96,[2,13]],
		"/(protected)/people/(legacy)/scheduling/requests/time-off-requests/[status]": [97,[2,13]],
		"/(protected)/people/(legacy)/scheduling/requests/trade-requests/[status]": [98,[2,13]],
		"/(protected)/people/(legacy)/scheduling/settings": [99,[2]],
		"/(protected)/people/(legacy)/scheduling/[startDate]/[endDate]/day": [91,[2,12]],
		"/(protected)/people/(legacy)/scheduling/[startDate]/[endDate]/shift": [92,[2,12]],
		"/(protected)/people/(legacy)/scheduling/[startDate]/[endDate]/staff": [93,[2,12]],
		"/(protected)/people/(new)/team": [105,[2,14]],
		"/(protected)/people/(new)/team/archived-employees": [106,[2,14]],
		"/(protected)/people/(new)/team/departments": [107,[2,14]],
		"/(protected)/people/(new)/team/pending": [108,[2,14]],
		"/(protected)/people/(new)/team/roles": [109,[2,14]],
		"/(protected)/people/(new)/team/staff/[status]/[id]": [110,[2,14]],
		"/(protected)/profile/address": [111,[2,16]],
		"/(protected)/profile/general": [112,[2,16]],
		"/(protected)/profile/login-history": [113,[2,16]],
		"/(protected)/profile/security": [114,[2,16]],
		"/(protected)/reporting": [115,[2,17]],
		"/(protected)/reporting/bank-deposits": [116,[2,17]],
		"/(protected)/reporting/daily-summary": [~117,[2,17]],
		"/(protected)/reporting/daily-summary/[start]/[comparison]": [~118,[2,17]],
		"/(protected)/reporting/eod": [119,[2,17]],
		"/(protected)/reporting/gift-cards": [120,[2,17]],
		"/(protected)/reporting/gift-cards/balances": [121,[2,17]],
		"/(protected)/reporting/gift-cards/history": [122,[2,17]],
		"/(protected)/reporting/gift-cards/transactions": [123,[2,17]],
		"/(protected)/reporting/reports": [124,[2,17]],
		"/(protected)/reservations/general": [125,[2,18]],
		"/(protected)/reservations/notifications": [126,[2,18]],
		"/(protected)/reservations/online": [127,[2,18]],
		"/(protected)/reservations/reporting": [128,[2,18]],
		"/(protected)/reservations/service-periods": [129,[2,18]],
		"/(unprotected)/reset-password/[token]": [155],
		"/(unprotected)/select": [~156,[21]],
		"/(unprotected)/select/business": [~157,[21]],
		"/(protected)/settings/(pages)": [130,[2,19]],
		"/(protected)/settings/(pages)/account": [131,[2,19]],
		"/(protected)/settings/(pages)/general-service": [133,[2,19]],
		"/(protected)/settings/(pages)/general": [132,[2,19]],
		"/(protected)/settings/(pages)/online-ordering": [134,[2,19]],
		"/(protected)/settings/(pages)/pos": [135,[2,19]],
		"/(protected)/settings/(pages)/printing": [136,[2,19]],
		"/(protected)/store-setup/(pages)/comp-reasons": [137,[2,20]],
		"/(protected)/store-setup/(pages)/floor-plans": [138,[2,20]],
		"/(protected)/store-setup/(pages)/kds": [139,[2,20]],
		"/(protected)/store-setup/(pages)/order-kitchen-display": [~140,[2,20]],
		"/(protected)/store-setup/(pages)/printers": [141,[2,20]],
		"/(protected)/store-setup/(pages)/service-areas": [142,[2,20]],
		"/(protected)/store-setup/(pages)/service-fees": [143,[2,20]],
		"/(protected)/store-setup/(pages)/table-management": [144,[2,20]],
		"/(protected)/store-setup/(pages)/taxes": [145,[2,20]],
		"/(protected)/store-setup/(pages)/taxes/[id]": [146,[2,20]],
		"/(protected)/store-setup/(pages)/tip-outs": [147,[2,20]],
		"/(protected)/store-setup/(pages)/void-reasons": [148,[2,20]],
		"/(unprotected)/stripe-reader/register": [158]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';